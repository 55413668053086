.cover {
  background: var(--main-color-purple);
  border-top:10px solid var(--main-color-purple-ligth);
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-create {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background: var(--main-color-purple-ligth);
  color: var(--main-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-create:hover {
  background: #9e8e9e;
}

.boddy-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}