.dashboard-header-spinner {
  width: 100%;
}

.dashboard-header-spinner + div {
  width: 100% !important;
}

.dashboard-header-card {
  border: 1px solid rgba(var(--main-color-gray-rgb), 0.2);
  border-top: 10px solid var(--main-color-purple);
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  align-items: center;
  background: var(--main-color-white);
}

.dashboard-header-card-title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.dashboard-header-card-title > p {
  margin: 0;
  font-weight: bold;
}

.dashboard-header-card-value {
  display: flex;
  width: 100%;
  justify-content: center;
}

.dashboard-header-card-value > p {
  font-size: 2.2em;
  margin: 0;
  font-weight: 300;
  color: var(--main-color-gray);
}

.dashboard-header-card-footer {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

.dashboard-header-card-footer > p {
  margin: 0;
  font-weight: bold;
}