.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
}

.table > thead > tr {
  background: var(--main-color-purple-ligth);
  color: var(--main-color-white);
  border: 1px solid var(--main-color-purple-ligth);
}

.table > thead > tr > th {
  padding: 10px 10px;
  text-align: left !important;
}

.table > thead > tr > th > label > span {
  color: var(--main-color-white);
}

.table > tbody > tr {
  border: 1px solid rgba(var(--main-color-gray-rgb),.2);
}

.table > tbody > tr > td {
  padding: 5px 10px;
}

.table > tbody > tr > td:first-child{
  max-width: 200px;
  word-break: break-word;
}

.table > tbody > tr:hover {
  background-color: #f1eeee;
}