.channel-select-container {
  display: flex;
  padding: 0px 10px 10px 10px;
}

.channel-select-container > div {
  height: 35px;
  margin-right: 25px;
  padding: 5px;
}

.channel-select-container > div:hover {
  cursor: pointer;
}

.channel-select-container-selected {
  border-bottom: 3px solid var(--main-color-purple);
}

.channel-select-container-disabled {
  color: rgba(var(--main-color-gray-rgb),.4);
}

.channel-select-container-disabled :hover {
  cursor: auto !important;
}