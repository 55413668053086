.cards-metrics {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cards-metrics-content {
    display: flex;
    align-items: center;
    max-width: 300px;
}

.cards-metrics-item {
    margin-right: 10px;
    font-size: 15px;
    font-weight: bold;
    color: var(--main-color-orange);
}

.cards-metrics-description > p{
    margin: 0;
}

.cards-metrics-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cards-metrics-value > p {
    margin: 0;
    font-size: 2.0rem;
    color: var(--main-color-gray);
}

.cards-metrics-value-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cards-metrics-value-date > p {
    margin: 0;
    font-size: 1.0rem;
    color: var(--main-color-gray);
}
.yp-subtitle{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;  
    word-break: break-all;
}

.yp-title-user{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;  
    word-break: break-all;
    text-align: center;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
.custom-table th,
.custom-table td {
  border: none; /* Elimina las líneas de borde */
  padding: 8px;
  text-align: left;
}

.custom-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.custom-table td {
  border-bottom: 1px solid #ddd; /* Añade una línea en la parte inferior de las celdas para el diseño */
}

.custom-table tr:hover {
  background-color: #f1f1f1; /* Color de fondo al pasar el ratón por encima */
}

.p-noData {
  text-align: center;
}

.button-cell {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.yp-title-user {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
}