.title-upload-file{
  display: flex;
}
.title-text{
  align-items: center;
  display: flex;
  font-weight: bold;  
}
.title-text p{
  padding-left: 10px;
}

.file-dragger span{
  color: var(--main-color-purple-ligth);
  display: block;
  font-weight: 400;
  margin: 10px 0;
  text-transform: none;  
}
.file-dragger span:hover{
  opacity: 0.8;
}
.box-button{
  align-items: baseline;
  display: flex;
  flex-direction: column;
  margin-top:10px;
}
.box-button svg{
  margin-right: 15px;
}

.btn-create-company .btn-secondary-create-company{
  font-weight:700;
  line-height: 2px;
  padding: 16px 20px;
}

.btn-create-company .btn-secondary-create-company:hover{
  border: 1px solid var(--main-color-black) !important;
  color:var(--main-color-black) !important;
  transition-duration: .4s;
}

.btn-create-company .btn-primary-create-company{
  border:none !important;
  background: var(--main-color-orange-gradient);
  color: var(--main-color-purple);
  transition-duration: .4s;
  font-weight:700;
  line-height: 2px;
  padding: 16px 20px;
}

.btn-create-company .btn-primary-create-company:hover{
  background: rgba(var(--main-color-orange-rgb), .6);
  border: 1px solid var(--main-color-orange) !important;
}