.step-content {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
}

.step-buttons-container{
  border-left: 1px solid rgba(var(--main-color-black-rgb),.2);
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
}

.step-buttons {
  width: 90%;
  display: flex;
  justify-content: flex-end;
}

.step-header {
  margin-left: 10%;
  margin-right: 10%;
}

.step-content-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px rgba(var(--main-color-black-rgb),.2);
  padding: 20px;
  height: 100%;
}