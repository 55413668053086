.phone { 
  border: 1px solid var(--main-color-gray-ligth);
  border-top: 20px solid var(--main-color-gray-ligth);
  width: 100%;
  display: flex;
  padding: 10px;
}

.phone-field-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 20px;
}

.phone-content {
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 20px;
}

.phone-content > p {
  margin: 0;
  margin-left: 40px;
}

.phone-field {
  display: flex;
}

.wildcards {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.wildcard-title {
  display: flex;
}

.wildcard-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
}

.wildcard-content {
  display: flex;
  align-items: center;
}

.table-fields-header {
  border-bottom: 1px solid #f0f0f0;
  background: var(--main-color-purple);
  color: var(--main-color-white);
}