.yp-button {
  border: none;
  font-weight:bold;
  padding:4px 20px;
  text-transform: none;
  cursor: pointer;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
}

.yp-button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.yp-button-icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.yp-button-primary {
  background: var(--main-color-orange-gradient);
  color:var(--main-color-purple);
  transition-duration: 0.4s;
}

.yp-button-primary:hover {
  background: rgba(var(--main-color-orange-rgb), 0.6);
  border: 1px solid var(--main-color-orange);
}

.yp-button-primary:disabled {
  background: rgba(var(--main-color-orange-rgb), 0.3);
}

.yp-button-secondary {
  border: 1px solid rgba(var(--main-color-black-rgb),.2);
  background-color:var(--main-color-white);
  transition-duration: 0.4s;
}

.yp-button-secondary:hover {
  border: 1px solid var(--main-color-black);
}

.yp-button-secondary:disabled {
  background: rgba(var(--main-color-gray-ligth-rgb), 0.2);
}

.yp-button-optional {
  background: var(--main-color-purple-gradient);
  border: 1px solid var(--main-color-purple);
  color: var(--main-color-white);
  transition-duration: 0.4s;
}

.yp-button-optional:hover {
  background: rgba(var(--main-color-purple-rgb), 0.7);
  border-color: var(--main-color-purple);
}

.yp-button-optional:disabled {
  background: rgba(var(--main-color-purple-rgb), 0.5);
  color:var(--main-color-white);
}

.yp-button-link {
  background: none;
  box-shadow: none;
  padding: 0;
}