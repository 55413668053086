.whatsapp-templates-table th.ant-table-cell {
  background-color: #a28aa2;
  color: white;
}

.whatsapp-templates-table tr td:first-child {
  border: 1px solid rgba(var(--main-color-gray-rgb),.2) !important;
  border-right: none !important;
  border-left: unset;
}

.facebook-login-alert .anticon-info-circle svg {
  color: #2274f6;
}

.log-into-another-account {
  border: unset !important;
  box-shadow: unset !important;
  margin-bottom: 24px;
}

.waba-card .ant-card-head-wrapper {
  height: 100%;
}