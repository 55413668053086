.helper-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--main-color-purple);
  border: 2px solid var(--main-color-purple-ligth);
  margin-left: 10px;
  cursor: pointer;
}

.helper-button > span {
  color: white;
  font-size: .7rem;
  text-align: center; 
  width: 25px;
}

.helper-button:hover {
  background-color: var(--main-color-purple-ligth);
}