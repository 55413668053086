.card-metrics
{    
    margin:5px;    
}

.border-card
{
    border-left: 10px solid #5e475e;   
}

.smart-template-box
{   
    border-right: 2px solid #f2f2f2;
    height: calc(100vh - 95px);
}

.metrics-box
{
    height: calc(100vh - 95px);
}

.ant-layout-header{
    padding: 0;
}

.ant-layout-content{
    background: #FFF;
}

.title-smart-template
{
    border-bottom: 2px solid #F2F2F2;
    margin: 5px;
}

.title-metrics
{
    border-bottom: 2px solid #F2F2F2;
    margin: 5px;
}