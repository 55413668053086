.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  height: calc(100vh - 100px);
  padding: 20px 20px;
  border: 1px solid rgba(var(--main-color-black-rgb),.2);
  overflow-y: scroll;
}

.content-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px rgba(var(--main-color-black-rgb),.2);
  padding: 20px;
  height: 100%;
}

.content-card-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content-card-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form > label {
  margin-bottom: 5px;
}
.download-report-button {
  margin: 0;
  padding: 5px 30px;
  background: var(--main-color-purple);
  color: var(--main-color-white);
}