.btn-create-account .btn-secondary-create-account{
  font-weight:700;
  line-height: 2px;
  padding: 16px 20px;
}

.btn-create-account .btn-secondary-create-account:hover{
  border: 1px solid var(--main-color-black) !important;
  color:var(--main-color-black) !important;
  transition-duration: .4s;
}

.btn-create-account .btn-primary-create-account{
  border:none !important;
  background: var(--main-color-orange-gradient);
  color: var(--main-color-purple);
  transition-duration: .4s;
  font-weight:700;
  line-height: 2px;
  padding: 16px 20px;
}

.btn-create-account .btn-primary-create-account:hover{
  background: rgba(var(--main-color-orange-rgb), .6);
  border: 1px solid var(--main-color-orange) !important;
}