:root {
  --main-color-white: #FFFFFF;
  --main-color-white-rgb: 255, 255, 255;
  --main-color-gray: #808080;
  --main-color-gray-rgb: 128, 128, 128;
  --main-color-gray-ligth: #C9C3C9;
  --main-color-gray-ligth-rgb: 201, 195, 201;
  --main-color-purple: #5E475E;
  --main-color-purple-rgb: 94, 71, 94;
  --main-color-purple-ligth: #a28aa2;
  --main-color-purple-gradient: linear-gradient(90deg, rgba(133,98,133,1) 0%, rgba(140,104,140,1) 50%, rgba(110,82,110,1) 100%);
  --main-color-black: #444444;
  --main-color-black-rgb: 68, 68, 68;
  --main-color-black-gradient: linear-gradient(90deg, rgba(77,77,77,1) 0%, rgba(77,77,77,1) 50%, rgba(51,51,51,1) 100%);
  --main-color-orange: #FF9F00;
  --main-color-orange-rgb: 255, 159, 0;
  --main-color-orange-gradient: linear-gradient(90deg, rgba(255,176,0,1) 0%, rgba(255,159,0,1) 50%, rgba(255,152,0,1) 100%);
  --main-color-orange-ligth: #FFB000;
  --main-color-red: #BF4D47;
  --main-color-red-rgb: 191, 77, 71;
  --main-color-green: #006978;
  --main-color-green-rbg: 0, 105, 120;
  --main-color-pink: #FFF2F0;
  --main-color-pink-rbg: 255, 242, 240;
  --main-color-violet: #272257;
  --main-color-magenta: #AC2A72;
}

/*------- Start General Styles -------*/
html {
  font-family: 'Lato', sans-serif;
}

body{
  overflow: hidden;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}


/* @import '~@fortawesome/fontawesome-free/css/all.css'; */


.user-header{
  justify-content: end;
  margin-right: 20px;
}
.lengauage-selector{
  margin-right:20px !important;
}
.user-header .user-item{
  align-items: center;
  display: flex;
  margin-left: 20px; 
}
.user-header .user-item-character{
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 146px;
  white-space: nowrap; 
}
.user-header .user-item .item-icon{
  margin-right: 10px;
}

.user-header .user-box{
  max-width: 100%;
}

.yp-workspace {
  margin: 10px;
}

.yp-bakground-gray {
  background-color:  rgba(var(--main-color-gray-rgb), 0.18);
}

.yp-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.yp-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
}

.yp-grid-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}

.yp-margin-top-25{
  margin-top: 25px !important;
}

.yp-workspace {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 157px);
  padding: 40px 40px 20px 40px;
  margin: 0;
  border: 1px solid rgba(var(--main-color-black-rgb),.2);
  overflow-y: scroll;
}

.yp-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.yp-content-white {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--main-color-white);
}

.yp-aling-vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.yp-strong-value {
  font-size: 30px;
  color: var(--main-color-purple);
  margin: 0;
}

.yp-strong-value-small {
  font-size: 15px;
  margin: 0;
  color: var(--main-color-gray);
}

.yp-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.yp-subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.yp-mt-1 {
  margin-top: 5px;
}

.yp-mt-2 {
  margin-top: 10px;
}

.yp-mt-3 {
  margin-top: 20px;
}

.yp-mt-4 {
  margin-top: 40px;
}

.yp-mb-1 {
  margin-bottom: 5px;
}

.yp-mb-2 {
  margin-bottom: 10px;
}

.yp-mb-3 {
  margin-bottom: 20px;
}

.yp-mb-4 {
  margin-bottom: 40px;
}

.ant-card-head-title {
  padding: 6px 0 !important;
}

.ant-card-extra {
  padding: 12px 0 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--main-color-gray-ligth) !important;
}

.ant-input { 
  border: 1px solid var(--main-color-gray-ligth) !important;
}

.ant-picker { 
  border: 1px solid var(--main-color-gray-ligth) !important;
}

.ant-btn {
  border: 1px solid var(--main-color-gray-ligth) !important;
 }

.ant-input-number {
  border: 1px solid var(--main-color-gray-ligth) !important;
}

.ant-menu li svg{
  margin-right: 10px;
  width: 16px !important;
}
#menu-item-2-- span{
  margin-left: 0;
}
[data-menu-id="rc-menu-uuid-93709-1-menu-item-1--"] {
  /* Estilos personalizados */
  background-color: lightblue;
  color: white;
  font-weight: bold;
}
[data-menu-id="rc-menu-uuid-35513-1-menu-item-1--"] {
  /* Estilos personalizados */
  background-color: lightblue;
  color: white;
  font-weight: bold;
}
.ant-menu .ant-menu-submenu-selected .ant-menu .ant-menu-item-selected svg{
  color: var(--main-color-white);
}
.reports + li .ant-menu-submenu-title{
  padding-left:53px !important; 
}
.reports + li .ant-menu-submenu-title:before{ 
  content: '\f2b9';
  display: block;
  font-family: "FontAwesome", sans-serif;
  height: 16px; 
  position: absolute;
  left: 24px;
  transform: translateY(-50%);
  bottom: 40%;
  width: 16px;  
}

.reports + li + li .ant-menu-submenu-title{
  padding-left:53px !important; 
}
.reports + li + li .ant-menu-submenu-title:before{
  content: '\f802';
  display: block;
  font-family: "FontAwesome", sans-serif;
  height: 16px; 
  position: absolute;
  left: 24px;
  transform: translateY(-50%);
  bottom: 40%;
  width: 16px; 
}

.reports + li .ant-menu-item{
  padding-left:74px !important; 
}
.reports + li .ant-menu-item:before{
  content: '\f03a';
  display: block;
  font-family: "FontAwesome", sans-serif;
  height: 16px; 
  position: absolute;
  left: 50px;
  transform: translateY(-50%);
  bottom: 40%;
  width: 16px; 
}

.reports + li .ant-menu-item.ant-menu-item-selected:before{ 
  color: var(--main-color-white);
}

.reports + li .ant-menu-item{
  padding-left:78px !important; 
}
.reports + li .ant-menu-item + .ant-menu-item:before{
  content: '\f46d';
  display: block;
  font-family: "FontAwesome", sans-serif;
  height: 16px; 
  position: absolute;
  left: 50px;
  transform: translateY(-50%);
  bottom: 40%;
  width: 16px; 
}

.reports + li + li .ant-menu-item + .ant-menu-item.ant-menu-item-selected:before{
  color: var(--main-color-white);
}
.reports + li + li .ant-menu-item{
  padding-left:74px !important; 
}
.reports + li + li .ant-menu-item:before{
  content: '\f0c5';
  display: block;
  font-family: "FontAwesome", sans-serif;
  height: 16px; 
  position: absolute;
  left: 50px;
  transform: translateY(-50%);
  bottom: 40%;
  width: 16px; 
}


.reports + li + li .ant-menu-item.ant-menu-item-selected:before{
  color: var(--main-color-white);
}

.ant-layout-sider-collapsed a{
  color: transparent !important;
}
.ant-layout-sider-collapsed .ant-menu-title-content span{
  color: transparent !important;
}

.ant-layout-sider-collapsed .reports + li .ant-menu-submenu-title:before{
  left: 33px;
}
.ant-layout-sider-collapsed .reports + li + li .ant-menu-submenu-title:before{
  left: 33px;
} 




.text-shortlink{
  max-width: 845px;
  padding: 20px;
}
/*.ant-menu-item-only-child{
  padding-left:47px !important;
  position: relative;
}

.ant-menu-item-only-child::before{
  width: 16px; 
  height: 16px; 
  background-image: url('assets/resources/document.svg');
  background-repeat: no-repeat; 
  display: block;
  content: '';
  position: absolute;
  left: 24px;
}
.ant-menu-item-only-child.ant-menu-item-selected::before{
  display: none;
}
.ant-menu-item-only-child:hover::before{
  width: 16px; 
  height: 16px; 
  background-image: url('assets/resources/documenthover.svg');
  background-repeat: no-repeat; 
  display: block;
  content: '';
  position: absolute;
  left: 24px;
}*/

aside .ant-menu-root {
  max-height: 80vh;
  overflow-x: hidden;    
  overflow-y: auto;
}
.bar-graph canvas{
  width: 100% !important;
}
.ant-steps-icon{
  width: 32px;
}

.wildcard-message{
  background-color: var(--main-color-purple);
  border-radius: 10px;
  color: var(--main-color-white);
  display: inline-flex;  
  margin-bottom: 2px;
  padding: 4px 10px;
  text-align: left;  
}


/*------- End General Styles -------*/

/*------- Start Scrollbar -------*/

/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--main-color-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-color-gray);
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--main-color-gray-rgb), 0.5)
}

/*------- End Scrollbar -------*/

/*---------antd checkbox-----------*/

.ant-checkbox .ant-checkbox-inner,.ant-checkbox .ant-checkbox-inner:hover,.ant-checkbox .ant-checkbox-inner:focus{
  border-color: rgba(var(--main-color-gray-rgb), 0.5) !important;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: var(--main-color-purple) !important;
  border-color: var(--main-color-purple) !important;
}
.ant-checkbox-checked::after {
  border-color: var(--main-color-purple) !important;}

/*---------antd checkbox----------*/

/*------- Start Notification -------*/

.ant-notification-notice-message {
  color: var(--main-color-white) !important;
}

.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: var(--main-color-white) !important;
  outline: none;
}

/*------- End Notification -------*/

/*------- Start Modal -------*/

.ant-modal-header {
  background: var(--main-color-purple) !important;
}

.ant-modal-header > .ant-modal-title {
  color: var(--main-color-white) !important;
}

/*------- End Modal -------*/


/*------- Start border Menu -------*/

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: none !important;
}

/*------- End border Menu -------*/

/*------- Start Logo -------*/

.logo {
  padding: 5px;
}

/*------- End Logou -------*/

/*------- Start selected card view -------*/

.selected-card-view {
  display: flex !important;
  align-items: center;
  flex-direction: row-reverse;
  border-top: 1px solid rgba(var(--main-color-black-rgb),.2);
}

/*------- End selected card view -------*/

/*------- Star subheader -------*/

.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(var(--main-color-black-rgb),.2);
  border-left: 1px solid rgba(var(--main-color-black-rgb),.2);
  padding: 5px 0px 5px 10px;
}

/*------- End subheader -------*/

/*------- Start Step Ant Component custom colors -------*/

.ant-steps-item-process .ant-steps-item-icon{
  border: 4px solid var(--main-color-orange-ligth) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--main-color-purple) !important;
  border: 4px solid var(--main-color-purple-ligth) !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--main-color-white) !important;
}

.ant-drawer-content-wrapper{
  width: 840px !important;
}

.ant-steps-item-icon{
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-steps-item-container{
  display: flex !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--main-color-purple) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--main-color-purple) !important;
}

/*------- End Step Ant Component custom colors -------*/



/*------- Start Ant menu links -------*/

.ant-menu-inline .ant-menu-item::after {
  border-right: 5px solid var(--main-color-purple-ligth) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--main-color-orange) !important;
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: var(--main-color-white) !important;
}

/*------- End Ant menu links -------*/


/*------- Start Chatbot -------*/

.chatBot {
  position: fixed;
  bottom:7px;
	right:20px;
}

.chatBot-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--main-color-purple);
  color: var(--main-color-white);
  cursor: pointer;
  box-shadow: inset -1px 3px 8px 5px var(--main-color-purple), 2px 5px 16px 0px var(--main-color-gray), 5px 5px 7px 5px rgba(151,255,181,0);
}

.chatBot-button:hover {
  background: rgba(var(--main-color-purple-rgb),.8);
  border: 1pxx solid rgba(var(--main-color-black-rgb),.2);
}

.chatBot-button:active {
  background: var(--main-color-purple);
}

.ant-menu-item-selected svg{
  color: var(--main-color-white);
}

/*------- End Chatbot -------*/

/*-----start dragger-------*/
.ant-upload.ant-upload-drag{
  border: 3px dashed rgba(var(--main-color-gray-rgb), 0.5)  !important;

}

/*------End dragger-----*/


/*-----Copy code of code block-------*/
.eTSKtV {
  background: var(--main-color-white) !important;
  color: var(--main-color-purple) !important;
}
/*-----Copy code of code block-------*/


/*------- Start Media Queries -------*/

.ant-layout-sider-collapsed a{
  color: transparent !important;
}
@media (max-width: 1400px) {
  aside .ant-menu-root {
    max-height: 75vh;    
  } 
}

@media (max-width: 1600px) {
  /* .yp-grid-cards {
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  } */  
}

@media (max-width: 1200px) {
  .yp-strong-value {
    font-size: 25px;
  }

  .yp-strong-value-small {
    font-size: 25px;
  }
}

@media (max-width: 923px) {
  /* .yp-grid-cards {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  } */
}

@media (max-width: 768px) {
  body{
    overflow:initial;
  }
}

@media (max-width: 360px) {
  /* .yp-grid-cards {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  } */
}



/*------- End Media Queries -------*/
